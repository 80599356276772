import React, { useState, useContext, useEffect } from "react";

import { CurrentUserContext } from "Container/CurrentUser";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LogoPaps from "assets/images/logo1.png";
import { CustomSpinner } from "Components/Loader";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  FormStyled,
  TextFieldStyled,
  ButtonStyled,
  FormHeader,
  TypographyStyled,
} from "./styles";
import { withRouter } from "react-router-dom";

const LoginPage = withRouter((props) => {
  const [fields, setFields] = useState({ email: null, password: null });
  const [status, setStatus] = useState({
    loading: false,
    error: false,
    success: false,
  });
  const { signIn, status: loginStatus } = useContext(CurrentUserContext);

  useEffect(() => {
    if (loginStatus.code === "ok") {
      props.history.push("/");
    }
  }, [loginStatus.code, props.history]);

  function handleChange(e) {
    setFields({ ...fields, [e.target.name]: e.target.value });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    event.persist();
    setStatus({ loading: true });
    const { uid } = await signIn(fields);
    console.log(fields);

    console.log(uid);
    if (uid) {
      setStatus({ loading: false, success: true });
    } else {
      setStatus({ loading: false, error: true });
    }
  }
  const handleClickShowPassword = () => {
    setFields({ ...fields, showPassword: !fields.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <FormHeader>
        {status.loading ? (
          <CustomSpinner />
        ) : (
          <img src={LogoPaps} alt="Logo de paps" width={60} />
        )}
        <TypographyStyled variant="h5">
          {loginStatus.code && loginStatus.code !== "ok" ? (
            <span style={{ color: "red", marginTop: "1rem" }}>
              {loginStatus.message}
            </span>
          ) : (
            "Veuillez vous connecter avec vos identifiants"
          )}
        </TypographyStyled>
      </FormHeader>
      <FormStyled onSubmit={handleSubmit} noValidate>
        <TextFieldStyled
          onChange={handleChange}
          name="email"
          label="Email"
          type="email"
          autoComplete="email"
          variant="outlined"
          required
        />

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            style={{
              width: "17.5rem",
            }}
            onChange={handleChange}
            name="password"
            label="Password"
            type={fields.showPassword ? "text" : "password"}
            autoComplete="current-password"
            variant="outlined"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {fields.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            required
            labelWidth={70}
          />
        </FormControl>
        <ButtonStyled
          type="submit"
          variant="contained"
          color="primary"
          classes={{ root: "custom" }}
          {...(status.loading && { disabled: true })}
        >
          Se Connecter
        </ButtonStyled>
      </FormStyled>
    </>
  );
});

export default LoginPage;
