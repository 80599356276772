import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { CurrentUserContext } from "Container/CurrentUser";
import { HomeGridContainer, HomeGridColumn } from "./styles";
import "styled-components/macro";
import { HeaderNav } from "Components/HeaderNav";

export interface ButtonLinkProps extends ButtonProps {
  to: string;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ to, ...rest }) => (
  <Button
    component={({ innerRef, ...linkProps }) => <Link {...linkProps} to={to} />}
    {...rest}
    css={`
      padding: 0.2rem 0;
      width: 13rem;
    `}
  />
);

export const HomePage = withRouter(props => {
  const { isLoggedIn, setLoggedIn } = useContext(CurrentUserContext);

  useEffect(() => {
    function isHeLoggedIn() {
      if (window.localStorage.getItem("user")) {
        setLoggedIn(true);
      } else {
        props.history.push("/login");
      }
    }

    isHeLoggedIn();
    return () => isHeLoggedIn();
  }, [isLoggedIn, setLoggedIn, props.history]);
  return (
    <React.Fragment>
      <HeaderNav />
      <HomeGridContainer>
        <HomeGridColumn item="left">
          <h1>Espace clients</h1>
          <p>
            Accéder à la liste des clients, ajouter une nouvelle mission, faire
            le suivi d’un client, etc...
          </p>
          <div>
            <ButtonLink
              variant="outlined"
              color="secondary"
              size="medium"
              to="/operation/add"
            >
              Ajouter un client
            </ButtonLink>
          </div>
          <div>
            <ButtonLink
              variant="contained"
              color="secondary"
              size="medium"
              to="/operation/list"
            >
              Voir la liste
            </ButtonLink>
          </div>
        </HomeGridColumn>
        <HomeGridColumn item="right">
          <h1>Espace papsers</h1>
          <p>
            Accéder à la liste des Papser, ajouter un nouveau papser, renseigner
            le type de contrat, les demandes d'intégrations, etc...
          </p>
          <div>
            <ButtonLink
              variant="outlined"
              color="primary"
              size="medium"
              to="/papser/add"
            >
              Ajouter un papser
            </ButtonLink>
          </div>
          <div>
            <ButtonLink
              variant="contained"
              color="primary"
              size="medium"
              to="/papser/list"
            >
              Voir la liste
            </ButtonLink>
          </div>
        </HomeGridColumn>
      </HomeGridContainer>
    </React.Fragment>
  );
});
