import styled, { css } from "styled-components";
import { colors } from "styles/_utils";

export const HomeGridContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: calc(100vh - 67px);
`;

export interface HomeGridColumnProps {
  item: "left" | "right";
}

export const HomeGridColumn = styled("section")<HomeGridColumnProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 50%;
  flex-basis: 50%;
  padding-top: 5rem;

  h1 {
    font-size: 1.8rem;
  }

  ${p =>
    p.item === "left" &&
    css`
      background-color: ${colors.blueLight};
      color: #fff;
    `}

  ${p =>
    p.item === "right" &&
    css`
      background-color: #fff;
    `}

  p {
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    font-weight: 100;
    width: 70%;
  }

  > div {
    margin: 0 auto 2rem;
  }
`;
