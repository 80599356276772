/* eslint-disable no-template-curly-in-string */
import React, { useState, useCallback, useEffect, useContext } from "react";
import { TableRow } from "./styles";
import { TableRowsComponent } from "Container/Table/client";
import { FilterableTableContext } from "Container/FilterableTable";
import TablePaginationActions from "utils/datatable";
import {
  Table,
  TableBody,
  Paper,
  TablePagination,
  TableFooter
} from "@material-ui/core";

export const ListOperation = ({ clients: rows }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [filteredData, setFilteredData] = useState(null);
  const dataToShow = filteredData || rows;
  const { searchTerm, setSearchTerm } = useContext(FilterableTableContext);

  const makeFilteredSearch = useCallback(() => {
    const filteredData = rows.filter(item => {
      return Object.keys(item).some(key => {
        return (
          typeof item[key] !== "object" &&
          String(item[key])
            .toLowerCase()
            .includes(searchTerm)
        );
      });
    });

    setFilteredData(filteredData);
  }, [rows, searchTerm]);

  useEffect(() => {
    if (rows && searchTerm && searchTerm.trim() !== "") {
      makeFilteredSearch();
    } else {
      setFilteredData(null);
    }
  }, [makeFilteredSearch, rows, searchTerm, setSearchTerm]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(Number(event.target.value));
  }
  if (dataToShow && dataToShow.length === 0) {
    return (
      <EmptyTable>
        {searchTerm && (
          <React.Fragment>
            Aucun résultat trouvé pour la recherche{" "}
            <i className="highlighted">{searchTerm}</i>
          </React.Fragment>
        )}
        {!searchTerm &&
          "Aucune donnée à afficher, essayez d'affiner votre recherche"}
      </EmptyTable>
    );
  }

  return (
    <React.Fragment>
      <Paper>
        <Table>
          <TableBody>
            {dataToShow && dataToShow.length !== 0 && (
              <React.Fragment>
                {dataToShow
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((val, index) => {
                    return (
                      <TableRowsComponent
                        key={`tr-${index}`}
                        currentCell={val}
                      />
                    );
                  })}
              </React.Fragment>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 16, 25]}
                colSpan={3}
                count={dataToShow.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

const EmptyTable = ({ children }) => (
  <div
    style={{
      margin: " 1.7rem auto 6rem",
      width: "96.6vw",
      border: "1px dashed #ccc"
    }}
  >
    <div
      style={{
        padding: "3.6rem 1rem",
        borderRadius: "6px",
        textAlign: "center",
        margin: "0 auto",
        fontSize: "1.4rem",
        fontWeight: "400",
        color: "${p => p.color || '#959595'}"
      }}
    >
      {children}
    </div>
  </div>
);
