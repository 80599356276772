import React from 'react';
import Form from './Form';

class TheForm extends React.Component {
	state = {
		renderCount: 0
	};

	handleFormSuccess = () => {
		this.setState(prevState => ({
			renderCount: prevState.renderCount + 1
		}));
	};

	render() {
		const { renderCount } = this.state;
		return <Form key={renderCount} onFormSuccess={this.handleFormSuccess} />;
	}
}

export default TheForm;
