import styled from "styled-components";
import { TableCell } from "@material-ui/core";

export const CustomTableCell = styled(TableCell)`
  font-size: 0.78rem;
  font-weight: 600;
  text-transform: initial;
  flex: ${p => `0 0 ${p.width}rem`};
  justify-content: ${p => p.align || "flex-start"};
  padding: 0;
  padding-right: ${p => (p.align === "right" ? 0 : "0.5rem")};
`;

export const StickyDiv = styled.div`
  position: sticky;
  top: 0;
  zindex: 129;
  background-color: white;
`;
