const FormatMonth = {
  0: "Jan.",
  1: "Fév.",
  2: "Mar.",
  3: "Avr.",
  4: "Mai.",
  5: "Jui.",
  6: "Juil.",
  7: "Aoû.",
  8: "Sept.",
  9: "Oct.",
  10: "Nov.",
  11: "Déc."
};

export const formatStringToDate = createAt => {
  const date = new Date(createAt);
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  return `${day} ${FormatMonth[month]} ${year} `;
};
