import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import TextField from "../TextField";

const searchOptions = {
  componentRestrictions: { country: ["sn", "bf", "ci","bj"] }
};

const SearchInputAddress = ({
  address,
  handleAddressChange,
  handleSelect,
  isWorkAddress
}) => (
  <PlacesAutocomplete
    value={address}
    onChange={handleAddressChange}
    onSelect={handleSelect}
    searchOptions={searchOptions}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <React.Fragment>
        <TextField
          {...getInputProps({
            placeholder: "Adresse de l' entreprise",
            className: "location-search-input",
            label: "Adresse de l' entreprise"
          })}
        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {suggestions.map(suggestion => {
            const className = suggestion.active
              ? "suggestion-item--active"
              : "suggestion-item";
            // inline style for demonstration purpose
            const style = suggestion.active
              ? { backgroundColor: "#fafafa", cursor: "pointer" }
              : { backgroundColor: "#ffffff", cursor: "pointer" };
            return (
              <div
                {...getSuggestionItemProps(suggestion, {
                  className,
                  style
                })}
              >
                <strong>{suggestion.formattedSuggestion.mainText}</strong>{" "}
                <small>{suggestion.formattedSuggestion.secondaryText}</small>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    )}
  </PlacesAutocomplete>
);

export default SearchInputAddress;
