import React from "react";
import Button from "@material-ui/core/Button";
import firebase from "../../config/firebase";
import TextField from "../TextField";
import Validated from "@material-ui/icons/CheckCircle";
import { FormStyled, DivStyled, SpanStyled } from "./styles";

const errorMsgs = {
  name: "Remplissez ce champ",
  codePapser: "Le code doit être composé exactement de 6 chiffres"
};

const Status = [
  {
    value: "full",
    label: "Embauché"
  },
  {
    value: "partial",
    label: "temps partiel"
  }
];

const areAllKeysValid = obj =>
  !Object.keys(obj).some(key => null == obj[key] || "" === obj[key]);
const formDataPapser = firebase.database().ref("papsers-code");

const InitialState = {
  fields: {
    codePapser: "",
    name: "",
    status: "full"
  },
  errors: {
    codePapser: false
  },
  hasErrors: false,
  isSubmitting: false,
  hasFormSuccess: false
};

export default class Form extends React.Component {
  state = InitialState;

  resetToInitialState = () => {
    this.setState({ ...InitialState, hasFormSuccess: true });
  };

  handleInputChange = (e, stateKey, stateKeyValue) => {
    e.persist();
    this.setState(prevState => ({
      [stateKey]: { ...prevState[stateKey], [stateKeyValue]: e.target.value }
    }));
  };

  handlenameChange = e => {
    this.handleInputChange(e, "fields", "name");
  };

  handlecodePapserChange = e => {
    this.handleInputChange(e, "fields", "codePapser");
  };

  handlecodePapserBlur = e => {
    this.handlecodePapserChangeValidation(e);
  };

  handlecodePapserChangeValidation = e => {
    const valInput = e.target.value;
    const iscodePapserCorrect = /^\d+$/.test(valInput);
    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        codePapser: !iscodePapserCorrect
      }
    }));
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    if (areAllKeysValid(this.state.fields) && !this.state.errors.codePapser) {
      this.setState({ isSubmitting: false, hasErrors: false });

      setTimeout(() => {
        this.sendFieldValuesToFirebase();
      }, 500);
    } else {
      this.setState({ hasErrors: true });
    }
  };

  sendFieldValuesToFirebase = () => {
    const createAt = new Date().toISOString();

    formDataPapser.push({
      ...this.state.fields,
      createAt
    });

    this.setState({ hasFormSuccess: true });
  };

  render() {
    const { errors, hasErrors } = this.state;
    return (
      <React.Fragment>
        {this.state.hasFormSuccess ? (
          <DivStyled>
            <Validated style={{ color: "green", fontSize: "10rem" }} />
            <h3>Enregistrer avec succés</h3>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={this.props.onFormSuccess}
            >
              Ajouter un papser
            </Button>
          </DivStyled>
        ) : (
          <FormStyled onSubmit={this.onSubmit}>
            <DivStyled>
              <TextField
                label="Code papser"
                placeholder="Numero du code papser"
                className="codePapser"
                onChange={this.handlecodePapserChange}
                onBlur={this.handlecodePapserBlur}
                {...errors.codePapser && {
                  error: true,
                  helperText: errorMsgs.codePapser
                }}
                required
              />
              <TextField
                label="Nom et prénom"
                placeholder="Nom et prénom"
                className="name"
                onChange={this.handlenameChange}
                required
              />

              <TextField
                label="Mode d'embauchement "
                placeholder="Mode d'embauchement"
                className="status"
                select
                onChange={this.handleChange}
                SelectProps={{
                  native: true
                }}
                required
              >
                {Status.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              <Button
                type="submit"
                id="save"
                variant="outlined"
                color="primary"
              >
                Ajouter
              </Button>
              {hasErrors && (
                <SpanStyled>
                  Vérifiez que tous les champs sont bien remplis
                </SpanStyled>
              )}
            </DivStyled>
          </FormStyled>
        )}
      </React.Fragment>
    );
  }
}
