import React, { useContext, useState } from "react";
import {
  Button,
  Tooltip,
  IconButton,
  SvgIcon,
  MenuItem
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { CurrentUserContext } from "Container/CurrentUser";
import { CustomHeaderMenu } from "./styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Dashboard from "@material-ui/icons/Dashboard";
import ViewList from "@material-ui/icons/ViewList";
import ArrowBack from "@material-ui/icons/ArrowBack";

import Logo from "assets/images/logo.png";

import {
  HeaderContainer,
  HeaderNavBreadCrumb,
  HeaderNavLogo,
  HeaderNavStyled,
  HeaderNavRight
} from "./styles";

import "styled-components/macro";

const SeparatorIcon = () => (
  <SvgIcon viewBox="0 0 9 18" color="inherit">
    <path d="M1.032 16.825l6-16 .936.35-6 16-.936-.35z" stroke="none"></path>
  </SvgIcon>
);

export const HeaderNav = withRouter(props => {
  const { location, history } = props;
  let position = "";
  const { email } = useContext(CurrentUserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  if (location.pathname === "/operation/list") {
    position = "Liste clients";
  } else if (location.pathname === "/operation/add") {
    position = "Nouveau client";
  } else if (location.pathname === "/papser/list") {
    position = "Liste papsers";
  } else if (location.pathname === "/papser/add") {
    position = "Nouveau papser";
  } else if (location.pathname === "/papser") {
    position = "Papser";
  } else if (location.pathname === "/operation") {
    position = "Client";
  } else {
    position = "Accueil";
  }

  console.log({ history, position });

  return (
    <HeaderNavStyled>
      <HeaderContainer>
        {location.pathname !== "/" ? (
          <IconButton color="inherit" onClick={() => history.goBack()}>
            <ArrowBack color="inherit" />
          </IconButton>
        ) : (
          <HeaderNavLogo src={Logo} alt="logo de Paps" height={40} />
        )}
        <HeaderNavBreadCrumb>
          <li>
            <Link to="/">Paps Internal</Link>
          </li>
          <li
            css={`
              height: 24px;
            `}
          >
            <SeparatorIcon />
          </li>
          <li>{position}</li>
        </HeaderNavBreadCrumb>
        <HeaderNavRight>
          <Tooltip title="Dashboard" placement="bottom">
            <Button
              color="inherit"
              component="a"
              target="_blank"
              href="https://dashboard.paps.sn/"
            >
              <Dashboard />
            </Button>
          </Tooltip>
          <Tooltip title="Recap" placement="bottom">
            <Button
              color="inherit"
              component="a"
              target="_blank"
              href="https://recap.paps.sn/"
            >
              <ViewList />
            </Button>
          </Tooltip>
          <Tooltip title="Déconnection" placement="bottom">
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <AccountCircle style={{ color: "white" }} />
            </IconButton>
          </Tooltip>
          <CustomHeaderMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>{email}</MenuItem>
            <MenuItem
              color="inherit"
              component={Link as any}
              {...({ to: "/logout" } as any)}
            >
              Déconnection
            </MenuItem>
          </CustomHeaderMenu>
        </HeaderNavRight>
      </HeaderContainer>
    </HeaderNavStyled>
  );
});
