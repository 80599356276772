import React, { useContext, useRef } from "react";
import { InputAdornment, IconButton, TextField } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Close";

import { FilterableTableContext } from "Container/FilterableTable";

export const SearchTableInput = () => {
  const { searchTerm, handleSearchTerm, setSearchTerm } = useContext(
    FilterableTableContext
  );
  const InputRef: React.MutableRefObject<HTMLInputElement> = useRef();

  function clearSearchTerm() {
    setSearchTerm(null);
    InputRef.current.value = "";
  }

  return (
    <TextField
      fullWidth
      inputRef={InputRef}
      variant="outlined"
      placeholder="Rechercher dans la table"
      name="searchTable"
      style={{
        width: "99%",
        marginLeft: "0"
      }}
      onChange={event => handleSearchTerm(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="inherit" color="action" />
          </InputAdornment>
        ),
        ...(searchTerm && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={clearSearchTerm}
                aria-label="Annuler la recherche"
              >
                <ClearIcon fontSize="small" color="action" />
              </IconButton>
            </InputAdornment>
          )
        })
      }}
      // eslint-disable-next-line
      inputProps={{
        style: {
          padding: "8.8px 0",
          fontSize: "15px"
        }
      }}
    />
  );
};
