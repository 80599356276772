import { useState, useCallback } from "react";
import firebase from "config/firebase";
const formDataPapser = firebase.database().ref("papsers-code");
export function useInputValues(initialValues) {
  let [values, setValues] = useState(initialValues);

  const onChangeItem = useCallback(
    event => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    },
    [values]
  );

  async function onSubmitItem(event, closeModal) {
    event.persist();
    event.preventDefault();
    const editedAt = new Date().toISOString();

    try {
      const res = await formDataPapser.child(values.key).update({
        ...values,
        editedAt
      });

      console.log(res);

      closeModal();
    } catch (error) {
      console.error(error);
    }
  }

  return {
    values,
    onChangeItem,
    onSubmitItem
  };
}
