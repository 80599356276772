import React, { createContext, useState, useCallback } from "react";
import { useThrottle } from "utils/hooks";

export interface IFilterableTableContext {
  searchTerm: string;
  setSearchTerm: React.Dispatch<string>;
  handleSearchTerm: (term: string) => void;
}

export const FilterableTableContext = createContext(
  {} as IFilterableTableContext
);

export const FilterableTableProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const throttledSearchTerm = useThrottle(searchTerm, 150);

  const handleSearchTerm = useCallback(
    (term: string) => setSearchTerm(term.toLowerCase()),
    []
  );

  return (
    <FilterableTableContext.Provider
      value={{
        searchTerm: throttledSearchTerm,
        setSearchTerm,
        handleSearchTerm
      }}
    >
      {children}
    </FilterableTableContext.Provider>
  );
};
