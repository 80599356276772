import styled from "styled-components";
import { TextField, Button, Typography } from "@material-ui/core";

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0 2.5rem;
  margin-top: 60;

  img {
    display: block;
  }
`;

export const FormStyled = styled("form")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextFieldStyled = styled(TextField as any)<any>`
  width: 280px;
  & input {
    padding: 1rem 0.7rem;
  }

  & + div {
    margin-top: 1.2rem;
  }
` as any;

export const ButtonStyled = styled(Button)`
  /* Le && est là pour forcer la spécifité (voir https://github.com/styled-components/styled-components/issues/1253#issuecomment-337871693) */
  && {
    margin-top: 1.2rem;
    padding: 0.7rem 0.5rem;
    width: 280px;
  }
`;

export const TypographyStyled = styled(Typography)`
  margin-top: 1.4rem;
  font-weight: 600;
  text-align: center;
`;
