import React from "react";
import { SearchTableInput } from "Components/Operation/searchTable";
import { Table, TableHead, TableRow } from "@material-ui/core";
import { CustomTableCell, StickyDiv } from "./styles";
import { HeaderValues, TableCellWidth } from "./_dataMaps";

export const Header = ({
  currentCell,
  whichTable
}: {
  currentCell: any;
  whichTable: keyof typeof HeaderValues;
}) => {
  const HeaderValuesTable = HeaderValues[whichTable];
  return (
    <StickyDiv>
      <SearchTableInput />
      <Table
        style={{
          backgroundColor: "rgba(249,249,249)",
          borderBottom: "solid 0.1px",
          borderColor: "rgba(230,230,230)"
        }}
      >
        <TableHead>
          <TableRow>
            {HeaderValuesTable.map(({ name, ...rest }, i) => (
              <CustomTableCell
                key={`thc-${i}`}
                children={name}
                width={TableCellWidth[rest.prop]}
                scope="col"
                {...rest}
              />
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </StickyDiv>
  );
};
