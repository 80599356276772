import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Form,
  DivContentInput,
  DeleteIconStyled,
  EditIconStyled
} from "./styles";
import { TableCellWidth, BodyValues } from "Container/Header/_dataMaps";
import { ClickAwayListener, Modal, Button } from "@material-ui/core";
import firebase from "config/firebase";
import { useInputValues } from "Components/Operation/_hooks";
import TextFieldWrapper from "Components/TextField";
import SearchInputAddress from "Components/SearchAddress";

const projectRef = firebase.database().ref("clients-code");

export const TableRowsComponent = ({ currentCell }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenRemove, setModalOpen2] = useState(false);
  const [anchorel, setAnchorel] = useState(null);
  const [anchorelremove, setAnchorelremove] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [currentCellData, setCurrentCellData] = useState(null);

  function handleClickToEdit(key) {
    setModalOpen(true);
    setCurrentCellData(key);
  }

  function handleCloseModal() {
    setModalOpen(false);
    setAnchorel(null);
  }
  function handleCloseModalRemove() {
    setModalOpen2(false);
    setAnchorelremove(null);
  }
  function removeProject(key) {
    projectRef.child(key).remove();
    handleCloseModalRemove();
  }
  function handleClickToDelete(key) {
    setModalOpen2(true);
    setCurrentCellData(key);
  }
  return (
    <>
      <TableRow>
        {BodyValues.Client.map((val, index) => (
          <TableCell
            key={`trc-${index}`}
            iscolumn={val.iscolumn}
            width={TableCellWidth[val.prop]}
            {...(val.align && { align: val.align })}
          >
            {val.customValue
              ? val.customValue(currentCell[val.prop])
              : currentCell[val.prop]}
          </TableCell>
        ))}
        <TableCell width={TableCellWidth.edit}>
          <EditIconStyled
            anchorel={anchorel}
            open={Boolean(anchorel)}
            onClose={() => setAnchorel(null)}
            onClick={() => handleClickToEdit(currentCell)}
          />
        </TableCell>
        <TableCell width={TableCellWidth.delete}>
          <DeleteIconStyled
            anchorelremove={anchorelremove}
            open={Boolean(anchorelremove)}
            onClose={() => setAnchorel(null)}
            onClick={() => handleClickToDelete(currentCell)}
          />
        </TableCell>
      </TableRow>
      {currentCell && (
        <Modal open={isModalOpen} onClose={handleCloseModal} tabIndex={-1}>
          <EditCurrentCellFom
            currentCell={currentCell}
            closeModal={handleCloseModal}
          />
        </Modal>
      )}
      {currentCell && (
        <Modal
          open={isModalOpenRemove}
          onClose={handleCloseModalRemove}
          tabIndex={-1}
        >
          <Form>
            <ClickAwayListener onClickAway={handleCloseModalRemove}>
              <DivContentInput>
                <span>Etes vous sûre de vouloir supprimer ce client ?</span>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    style={{ marginRight: "3rem" }}
                    variant="text"
                    color="secondary"
                    onClick={handleCloseModalRemove}
                  >
                    Annuler
                  </Button>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    onClick={() => removeProject(currentCell.key)}
                  >
                    Supprimer
                  </Button>
                </div>
              </DivContentInput>
            </ClickAwayListener>
          </Form>
        </Modal>
      )}
    </>
  );
};

const EditCurrentCellFom = ({ currentCell, closeModal }) => {
  const {
    values,
    onChangeItem,
    onSubmitItem,
    handleWorkAddressChange,
    handleWorkSelect
  } = useInputValues(currentCell);

  return (
    <Form className="form" onSubmit={event => onSubmitItem(event, closeModal)}>
      <ClickAwayListener onClickAway={closeModal}>
        <DivContentInput>
          <TextFieldWrapper
            label="Code client"
            name="codeClient"
            placeholder="Numero du code client"
            className="codeClient"
            defaultValue={values.codeClient}
            onChange={onChangeItem}
            required
          />
          <TextFieldWrapper
            label="Nom de l'entreprise"
            name="companyName"
            placeholder="Nom de l'entreprise"
            className="companyName"
            defaultValue={values.companyName}
            onChange={onChangeItem}
            required
          />
          <TextFieldWrapper
            label="Tarif"
            name="tarif"
            type="number"
            placeholder="Tarif"
            className="tarif"
            defaultValue={values.tarif}
            onChange={onChangeItem}
            required
          />
          <TextFieldWrapper
            label="Type de paiement "
            name="paymentType"
            placeholder="Type de paiement "
            className="paymentType"
            select
            defaultValue={values.paymentType}
            onChange={onChangeItem}
            SelectProps={{
              native: true
            }}
            required
          >
            {PaymentTypes.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextFieldWrapper>
          <SearchInputAddress
            address={values.workAddress}
            handleAddressChange={handleWorkAddressChange}
            handleSelect={handleWorkSelect}
          />
          <div style={{ marginTop: "2rem" }}>
            <Button
              type="submit"
              style={{ marginRight: "3rem" }}
              variant="text"
              color="secondary"
              onClick={closeModal}
            >
              Annuler
            </Button>
            <Button type="submit" variant="text" color="primary">
              Envoyer
            </Button>
          </div>
        </DivContentInput>
      </ClickAwayListener>
    </Form>
  );
};

const PaymentTypes = [
  {
    value: "monthly",
    label: "Facturation mensuelle"
  },
  {
    value: "account",
    label: "Compte client"
  },
  {
    value: "monthly-package",
    label: "Package mensuel"
  },
  {
    value: "tickets",
    label: "Tickets"
  },
  {
    value: "after-prestation",
    label: "Facturation après prestation"
  }
];
