import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyBYFGdCUsdCEBeEzNG4FFI0756H3lScKTI",
  authDomain: "paps-internal.firebaseapp.com",
  databaseURL: "https://paps-internal.firebaseio.com",
  projectId: "paps-internal",
  storageBucket: "paps-internal.appspot.com",
  messagingSenderId: "407081980027",
  appId: "1:407081980027:web:0976d1cd92fb4c237752de",
};

export const firebaseConfig = firebase.initializeApp(config);

export default firebase;
