import React from "react";
import { formatStringToDate } from "utils/date";

export const TableCellWidth = {
  codeClient: 5,
  companyName: 5,
  workAddress: 7,
  createAt: 6,
  paymentType: 6,
  tarif: 5,
  edit: 5,
  delete: 5
};

export const TableCellPapserWidth = {
  codePaper: 6,
  name: 5,
  createAt: 6,
  StatusMap: 7,
  edit: 5,
  delete: 5
};
export interface IHeaderValues {
  name: string;
  prop:
    | "codeClient"
    | "codePapser"
    | "name"
    | "companyName"
    | "workAddress"
    | "createAt"
    | "paymentType"
    | "status"
    | "tarif"
    | "edit"
    | "delete";
  width?: number;
  noGrow?: "true" | "false";
  align?: "center" | "left" | "right";
  iscolumn?: "true" | "false";
  customValue?: (value: any) => void;
}

const PaymentTypesMap = {
  monthly: "Facturation mensuelle",
  account: "Compte client",
  "monthly-package": "Package mensuel",
  tickets: "Tickets",
  afterPrestation: "Facturation après prestation"
};

export const HeaderClientValues: IHeaderValues[] = [
  {
    name: "Code",
    prop: "codeClient",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Entreprise",
    prop: "companyName",
    iscolumn: "true",
    align: "center"
  },
  {
    name: "Adresse",
    prop: "workAddress",
    iscolumn: "true",
    align: "center"
  },
  {
    name: "Création",
    prop: "createAt",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "paiement",
    prop: "paymentType",
    iscolumn: "true",
    align: "center"
  },

  {
    name: "Tarif (cfa)",
    prop: "tarif",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Modifier",
    prop: "edit",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Supprimer",
    prop: "delete",
    align: "center",
    iscolumn: "true"
  }
];

export const HeaderPapserValues: IHeaderValues[] = [
  {
    name: "Code",
    prop: "codePapser",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Nom et prénom",
    prop: "name",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Création",
    prop: "createAt",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Mode d'embauchement",
    prop: "paymentType",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Modifier",
    prop: "edit",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Supprimer",
    prop: "delete",
    align: "center",
    iscolumn: "true"
  }
];

export const TableClientValues: IHeaderValues[] = [
  {
    name: "Code",
    prop: "codeClient",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Entreprise",
    prop: "companyName",
    iscolumn: "true",
    align: "center"
  },
  {
    name: "Adresse",
    prop: "workAddress",
    iscolumn: "true",
    align: "center"
  },
  {
    name: "Création",
    prop: "createAt",
    align: "center",
    iscolumn: "true",
    customValue: value => <>{formatStringToDate(value)}</>
  },
  {
    name: "paiement",
    prop: "paymentType",
    iscolumn: "true",
    align: "center",
    customValue: value => <>{PaymentTypesMap[value]}</>
  },

  {
    name: "Tarif (cfa)",
    prop: "tarif",
    align: "center",
    iscolumn: "true"
  }
];

const StatusMap = {
  full: "Embauché",
  partial: "temps partiel"
};

export const TablePapserValues: IHeaderValues[] = [
  {
    name: "Code",
    prop: "codePapser",
    align: "center",
    iscolumn: "true"
  },
  {
    name: "Nom et prénom",
    prop: "name",
    align: "center",
    iscolumn: "true"
  },

  {
    name: "Création",
    prop: "createAt",
    align: "center",
    iscolumn: "true",
    customValue: value => <>{formatStringToDate(value)}</>
  },
  {
    name: "Mode d'embauchement",
    prop: "status",
    align: "center",
    iscolumn: "true",
    customValue: value => <>{StatusMap[value]}</>
  }
];

export const HeaderValues = {
  client: HeaderClientValues,
  papser: HeaderPapserValues
};
export const BodyValues = {
  Client: TableClientValues,
  Papser: TablePapserValues
};
