import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Operation from "Components/Operation";
import { MotherOfProviders } from "Container";
import Papser from "Components/Papser";
import { HomePage } from "pages/home";
import LogoutPage from "pages/logout";
import LoginPage from "pages/login";

const Routes = () => (
  <MotherOfProviders>
    <Router>
      <React.Fragment>
        <Route path="/" exact component={HomePage} />
        <Route path="/operation" component={Operation} />
        <Route path="/papser" component={Papser} />
        <Route path="/login" exact component={LoginPage} />
        <Route path="/logout" exact component={LogoutPage} />
      </React.Fragment>
    </Router>
  </MotherOfProviders>
);

export default Routes;
