import { createGlobalStyle } from "styled-components/macro";

import normalize from "./normalize";
import { colors } from "./_utils";

export const GlobalStyles = createGlobalStyle`
	body {
		color: ${colors.textPrimary};
	}
	
 ${normalize};
`;

export { JssProvider } from "./JssProvider";
export { muiTheme } from "./muiTheme";
