import React from "react";
import styled, { css } from "styled-components";
import {
  TableCell as TableCellBase,
  TableRow as TableRowBase
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

import { TableCellProps } from "@material-ui/core/TableCell";
export interface CustomTableCellProps extends TableCellProps {
  width?: number;
  iscolumn?: "true" | "false";
  shouldNotGrow?: boolean;
}

const TableCellClean = ({
  width,
  iscolumn,
  shouldNotGrow,
  ...other
}: CustomTableCellProps) => <TableCellBase {...other} />;

export const TableCell = styled(TableCellClean)<CustomTableCellProps>`
  overflow: hidden;
  flex: 0 0 ${p => p.width}rem;

  ${p =>
    p.iscolumn === "true" &&
    css`
      flex-direction: column;
    `};

  ${p =>
    p.align === "right" &&
    css`
      align-items: flex-end;
    `};

  ${p =>
    p.align === "center" &&
    css`
      align-items: center;
    `};

  padding-right: 0.4rem;
`;

export const TableRow = styled(TableRowBase)`
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid hsl(0, 0%, 88%);
  min-height: 3.02rem;
`;

export const Form = styled("form")`
  border-radius: 0.3rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 0px 5%;
`;

export const DivContentInput = styled("div")`
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  position: relative;
  max-width: 100%;
  width: 40rem;
  max-height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.1) 0px 1px 14px 0px;
  overflow-y: auto;
  border-radius: 8px;
  padding: 1rem;
`;

export const DeleteIconStyled = styled(Delete)`
 cursor: pointer;
              color: #f9553b;
              width: 2rem;
              background-color: #fff;
              border: 1px solid;
              border-color: #fff;
              border-radius: 5px";

`;

export const EditIconStyled = styled(Edit)`
 cursor: pointer;
              color: #3CB0F4;
              width: 2rem;
              background-color: #fff;
              border: 1px solid;
              border-color: #fff;
              border-radius: 5px";
`;
