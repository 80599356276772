import React, { Component } from "react";
import { Link } from "react-router-dom";
import Route from "react-router-dom/Route";
import List from "./listCompleted";
import TheForm from "./formCompleted";
import Add from "@material-ui/icons/PersonAdd";
import ListIcon from "@material-ui/icons/ViewList";
import Tooltip from "@material-ui/core/Tooltip";
import { HeaderNav } from "Components/HeaderNav";

import { FilterableTableProvider } from "Container/FilterableTable";

class Commercial extends Component {
  render() {
    return (
      <React.Fragment>
        <HeaderNav />
        <h1
          style={{
            textAlign: "center",
            margin: "0.5rem 0 0.5rem 0"
          }}
        >
          Code clients
        </h1>
        <FilterableTableProvider>
          <ul>
            <li>
              <Tooltip title="Ajouter Client" placement="top-start">
                <Link to="/operation/add">
                  <Add
                    style={{
                      fontSize: "2rem",
                      color: "#3cafbd",
                      margin: "0 1rem"
                    }}
                  />
                </Link>
              </Tooltip>
              <Tooltip title="Liste Clients" placement="top-start">
                <Link to="/operation/list">
                  <ListIcon
                    style={{
                      fontSize: "2rem",
                      color: "#3cafbd",
                      margin: "0 1rem"
                    }}
                  />
                </Link>
              </Tooltip>
            </li>
          </ul>
          <Route path="/operation/add" exact component={TheForm} />
          <Route path="/operation/list" exact component={List} />
        </FilterableTableProvider>
      </React.Fragment>
    );
  }
}

export default Commercial;
