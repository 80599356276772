import React from "react";
import firebase from "config/firebase";
import { objToArr } from "utils";
import { ListPapser } from "./list";
import { Header } from "Container/Header";
import OnLoading from "assets/images/paps-loading-icon.png";
import { DivLoading } from "./styles";

const formDataPapser = firebase.database().ref("papsers-code");

export default class List extends React.Component {
  state = {
    loading: true,
    clients: null
  };

  componentDidMount() {
    formDataPapser.on("value", snapshot => {
      this.setState({
        clients: snapshot.val(),
        loading: false
      });
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <DivLoading>
          <img width="80" src={OnLoading} alt="Recap is loading..." />
        </DivLoading>
      );
    }
    const { clients } = this.state;
    const papser = "papser";

    return (
      <React.Fragment>
        <div style={{ margin: "0 15px 0 15px" }}>
          <Header whichTable={papser} />
          <ListPapser clients={objToArr(clients)} />
        </div>
      </React.Fragment>
    );
  }
}
