import styled from "styled-components";
import { TableRow as TableRowBase } from "@material-ui/core";

export const TableRow = styled(TableRowBase)`
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid hsl(0, 0%, 88%);
  min-height: 3.02rem;
`;

export const FormStyled = styled("form")`
  border: solid 1px;
  border-color: #dbd6d6;
  width: 20rem;
  height: auto;
  border-radius: 4px;
  margin: 1rem auto;
  padding: 2rem 3rem;
`;

export const DivStyled = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const SpanStyled = styled("span")`
  color: red;
  display: flex;
  margin-left: 5rem;
`;

export const DivLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50%;
`;
