import { useState, useCallback } from "react";
import firebase from "config/firebase";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const formDataOperation = firebase.database().ref("clients-code");

export function useInputValues(initialValues) {
  let [values, setValues] = useState(initialValues);

  const onChangeItem = useCallback(
    event => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    },
    [values]
  );

  const handleWorkAddressChange = workAddress => {
    setValues(values => ({
      ...values,
      workAddress
    }));
  };

  const handleWorkSelect = workAddress => {
    handleWorkAddressChange(workAddress);

    geocodeByAddress(workAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log("Success", latLng))
      .catch(error => console.error("Error", error));
  };

  async function onSubmitItem(event, closeModal) {
    event.persist();
    event.preventDefault();
    const editedAt = new Date().toISOString();

    try {
      const res = await formDataOperation.child(values.key).update({
        ...values,
        editedAt
      });
      console.log(res);

      closeModal();
    } catch (error) {
      console.error(error);
    }
  }

  return {
    values,
    onChangeItem,
    handleWorkAddressChange,
    handleWorkSelect,
    onSubmitItem
  };
}
