import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Form,
  DivContentInput,
  DeleteIconStyled,
  EditIconStyled
} from "./styles";
import { TableCellPapserWidth, BodyValues } from "Container/Header/_dataMaps";
import { ClickAwayListener, Modal, Button } from "@material-ui/core";
import firebase from "config/firebase";
import { useInputValues } from "Components/Papser/_hooks";
import TextFieldWrapper from "Components/TextField";

const formDataPapser = firebase.database().ref("papsers-code");
const Status = [
  {
    value: "full",
    label: "Embauché"
  },
  {
    value: "partial",
    label: "temps partiel"
  }
];
export const TableRowsComponent = ({ currentCell }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [anchorel, setAnchorel] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [currentCellData, setCurrentCellData] = useState(null);
  const [isModalOpenDelete, setModalOpenDelete] = useState(false);
  const [anchoreldelete, setAnchoreldelete] = useState(null);
  function removeProject(key) {
    formDataPapser.child(key).remove();
    handleCloseModalDelete();
  }
  function handleClickToEdit(key) {
    setModalOpen(true);
    setCurrentCellData(key);
  }
  function handleCloseModal() {
    setModalOpen(false);
    setAnchorel(null);
  }
  function handleCloseModalDelete() {
    setModalOpenDelete(false);
    setAnchoreldelete(null);
  }
  function handleClickToDelete(key) {
    setModalOpenDelete(true);
    setCurrentCellData(key);
  }

  return (
    <>
      <TableRow>
        {BodyValues.Papser.map((val, index) => {
          return (
            <TableCell
              key={`trc-${index}`}
              iscolumn={val.iscolumn}
              width={TableCellPapserWidth[val.prop]}
              {...(val.align && { align: val.align })}
            >
              {val.customValue
                ? val.customValue(currentCell[val.prop])
                : currentCell[val.prop]}
            </TableCell>
          );
        })}
        <TableCell width={TableCellPapserWidth.edit}>
          <EditIconStyled
            anchorel={anchorel}
            open={Boolean(anchorel)}
            onClose={() => setAnchorel(null)}
            onClick={() => handleClickToEdit(currentCell)}
          />
        </TableCell>
        <TableCell width={TableCellPapserWidth.delete}>
          <DeleteIconStyled
            anchoreldelete={anchoreldelete}
            open={Boolean(anchoreldelete)}
            onClose={() => setAnchorel(null)}
            onClick={() => handleClickToDelete(currentCell)}
          />
        </TableCell>
      </TableRow>
      {currentCell && (
        <Modal open={isModalOpen} onClose={handleCloseModal} tabIndex={-1}>
          <EditCurrentCellFormPapser
            currentCell={currentCell}
            closeModal={handleCloseModal}
          />
        </Modal>
      )}
      {currentCell && (
        <Modal
          open={isModalOpenDelete}
          onClose={handleCloseModalDelete}
          tabIndex={-1}
        >
          <Form>
            <ClickAwayListener onClick={handleCloseModalDelete}>
              <DivContentInput>
                <span>Etes vous sûre de vouloir supprimer ce papser ?</span>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    style={{ marginRight: "3rem" }}
                    variant="text"
                    color="secondary"
                    onClick={handleCloseModalDelete}
                  >
                    Annuler
                  </Button>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    onClick={() => removeProject(currentCell.key)}
                  >
                    Supprimer
                  </Button>
                </div>
              </DivContentInput>
            </ClickAwayListener>
          </Form>
        </Modal>
      )}
    </>
  );
};

const EditCurrentCellFormPapser = ({ currentCell, closeModal }) => {
  const { values, onChangeItem, onSubmitItem } = useInputValues(currentCell);

  return (
    <Form className="form" onSubmit={event => onSubmitItem(event, closeModal)}>
      <ClickAwayListener onClickAway={closeModal}>
        <DivContentInput>
          <TextFieldWrapper
            label="Code papser"
            name="codePapser"
            placeholder="Numero du code papser"
            className="codePapser"
            defaultValue={values.codePapser}
            onChange={onChangeItem}
            required
          />
          <TextFieldWrapper
            label="Nom et prénom"
            name="name"
            placeholder="Nom du papser"
            className="name"
            defaultValue={values.name}
            onChange={onChangeItem}
            required
          />
          <TextFieldWrapper
            label="Mode d'embauchement"
            name="status"
            placeholder="Mode d'embauchement"
            className="status"
            select
            defaultValue={values.status}
            onChange={onChangeItem}
            SelectProps={{
              native: true
            }}
            required
          >
            {Status.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextFieldWrapper>

          <div style={{ marginTop: "2rem" }}>
            <Button
              type="submit"
              style={{ marginRight: "3rem" }}
              variant="text"
              color="secondary"
              onClick={closeModal}
            >
              Annuler
            </Button>
            <Button type="submit" variant="text" color="primary">
              Envoyer
            </Button>
          </div>
        </DivContentInput>
      </ClickAwayListener>
    </Form>
  );
};
