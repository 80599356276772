import React, { Component } from "react";
import Routes from "./Routes";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";

import { GlobalStyles, JssProvider, muiTheme } from "styles/index";

const theme = createMuiTheme(muiTheme);

class App extends Component {
  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <JssProvider>
            <MuiThemeProvider theme={theme}>
              <GlobalStyles />
              <Routes />
            </MuiThemeProvider>
          </JssProvider>
        </ThemeProvider>
      </>
    );
  }
}

export default App;
