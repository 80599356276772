import React from "react";
import Button from "@material-ui/core/Button";
import firebase from "config/firebase";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import SearchInputAddress from "../SearchAddress";
import TextField from "../TextField";
import Validated from "@material-ui/icons/CheckCircle";
import { FormStyled, DivStyled, SpanStyled } from "./styles";

const errorMsgs = {
  companyName: "Remplissez ce champ",
  codeClient: "Le code doit être composé exactement de 5 ou 6 chiffres",
  tarif: "*exemple: 2500"
};

const PaymentTypes = [
  {
    value: "monthly",
    label: "Facturation mensuelle"
  },
  {
    value: "account",
    label: "Compte client"
  },
  {
    value: "monthly-package",
    label: "Package mensuel"
  },
  {
    value: "tickets",
    label: "Tickets"
  },
  {
    value: "after-prestation",
    label: "Facturation après prestation"
  }
];

const areAllKeysValid = obj =>
  !Object.keys(obj).some(key => null == obj[key] || "" === obj[key]);
const formDataOperation = firebase.database().ref("clients-code");

const InitialState = {
  fields: {
    codeClient: "",
    companyName: "",
    tarif: "",
    paymentType: "monthly",
    workAddress: ""
  },
  errors: {
    codeClient: false,
    tarif: false
  },
  hasErrors: false,
  isSubmitting: false,
  hasFormSuccess: false
};

export default class Form extends React.Component {
  state = InitialState;

  resetToInitialState = () => {
    this.setState({ ...InitialState, hasFormSuccess: true });
  };

  handleInputChange = (e, stateKey, stateKeyValue) => {
    e.persist();
    this.setState(prevState => ({
      [stateKey]: { ...prevState[stateKey], [stateKeyValue]: e.target.value }
    }));
  };

  handleCompanyNameChange = e => {
    this.handleInputChange(e, "fields", "companyName");
  };

  handleCodeClientChange = e => {
    this.handleInputChange(e, "fields", "codeClient");
  };
  handleTarifChange = e => {
    this.handleInputChange(e, "fields", "tarif");
  };
  handlePaymentTypeChange = e => {
    this.handleInputChange(e, "fields", "paymentType");
  };

  handleCodeClientBlur = e => {
    this.handleCodeClientChangeValidation(e);
  };
  handleTarifBlur = e => {
    this.handleTarifValidation(e);
  };

  handleWorkAddressChange = workAddress => {
    this.setState(({ fields }) => ({
      fields: {
        ...fields,
        workAddress
      }
    }));
  };

  handleWorkSelect = workAddress => {
    this.handleWorkAddressChange(workAddress);

    geocodeByAddress(workAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log("Success", latLng))
      .catch(error => console.error("Error", error));
  };

  handleHomeAddressChange = homeAddress => {
    this.setState(({ fields }) => ({
      fields: {
        ...fields,
        homeAddress
      }
    }));
  };
  handleHomeSelect = homeAddress => {
    this.handleHomeAddressChange(homeAddress);

    geocodeByAddress(homeAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log("Success", latLng))
      .catch(error => console.error("Error", error));
  };

  handleCodeClientChangeValidation = e => {
    const valInput = e.target.value;
    const isCodeClientCorrect = /^\d+$/.test(valInput);
    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        codeClient: !isCodeClientCorrect
      }
    }));
  };
  handleTarifValidation = e => {
    const valInput = e.target.value;
    const istarifCorrect = /^\d+$/.test(valInput);
    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        tarif: !istarifCorrect
      }
    }));
  };
  onSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    if (
      areAllKeysValid(this.state.fields) &&
      !this.state.errors.tarif &&
      !this.state.errors.codeClient
    ) {
      this.setState({ isSubmitting: false, hasErrors: false });

      setTimeout(() => {
        this.sendFieldValuesToFirebase();
      }, 500);
    } else {
      this.setState({ hasErrors: true });
    }
  };

  sendFieldValuesToFirebase = () => {
    const createAt = new Date().toISOString();

    formDataOperation.push({
      ...this.state.fields,
      createAt
    });

    this.setState({ hasFormSuccess: true });
  };

  render() {
    const { errors, hasErrors, fields } = this.state;
    return (
      <React.Fragment>
        {this.state.hasFormSuccess ? (
          <DivStyled>
            <Validated style={{ color: "green", fontSize: "10rem" }} />
            <h3>Enregistrer avec succés</h3>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={this.props.onFormSuccess}
            >
              Ajouter un client
            </Button>
          </DivStyled>
        ) : (
          <FormStyled onSubmit={this.onSubmit}>
            <DivStyled>
              <TextField
                label="Code client"
                placeholder="Numero du code client"
                className="codeClient"
                onChange={this.handleCodeClientChange}
                onBlur={this.handleCodeClientBlur}
                {...errors.codeClient && {
                  error: true,
                  helperText: errorMsgs.codeClient
                }}
                required
              />
              <TextField
                label="Nom de l'entreprise"
                placeholder="Nom de l'entreprise"
                className="companyName"
                onChange={this.handleCompanyNameChange}
                required
              />
              <TextField
                label="Tarif"
                type="number"
                placeholder="Tarif"
                className="tarif"
                onChange={this.handleTarifChange}
                onBlur={this.handleTarifBlur}
                {...errors.tarif && {
                  error: true,
                  helperText: errorMsgs.tarif
                }}
                required
              />
              <TextField
                label="Type de paiement "
                placeholder="Type de paiement "
                className="paymentType"
                select
                onChange={this.handlePaymentTypeChange}
                SelectProps={{
                  native: true
                }}
                required
              >
                {PaymentTypes.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <SearchInputAddress
                address={fields.workAddress}
                handleAddressChange={this.handleWorkAddressChange}
                handleSelect={this.handleWorkSelect}
              />
              <Button
                type="submit"
                id="save"
                variant="outlined"
                color="primary"
              >
                Ajouter
              </Button>
              {hasErrors && (
                <SpanStyled>
                  Vérifiez que tous les champs sont bien remplis
                </SpanStyled>
              )}
            </DivStyled>
          </FormStyled>
        )}
      </React.Fragment>
    );
  }
}
