export const objToArr = object =>
  object &&
  Object.entries(object).map(([key, value]) => ({ key: key, ...value }));

const storage = window.localStorage;
export const getStoredUser = () => {
  if (storage.getItem("user")) {
    return JSON.parse(storage.getItem("user"));
  }
};
