import React from "react";
import TextField from "@material-ui/core/TextField";

const textFieldStyle = {
  width: "100%",
  marginBottom: "1.5rem"
};
const TextFieldWrapper = ({ ...props }) => (
  <TextField style={textFieldStyle} {...props} />
);

export default TextFieldWrapper;
